
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Device from "@/models/Device";
import * as rhovanion from "@/api/rhovanion";
import Notify from "@/utils/notifications";

interface AssetUpload {
  name: string;
  percentage: number;
  raw: RawUpload;
  size: number;
  status: string;
  uid: number;
}

interface RawUpload extends File {
  uid: number;
  lastModifiedDate: any;
  size: number;
  type: string;
}

@Component
export default class InventoryDrawer extends Vue {
  @Prop() device!: Device;
  @Prop() show!: boolean;
  @Prop() create!: boolean;
  @Prop() newDeviceData!: boolean;

  locations = {} as rhovanion.Payload<rhovanion.DeviceLocation>;
  states = {} as rhovanion.Payload<rhovanion.DeviceStatus>;
  categories = {} as rhovanion.Payload<rhovanion.DeviceCategory>;
  categorySelected: number | null = null;
  statusCategory = false; //If category is selected
  models: rhovanion.Model[] = [];
  assetsList: Array<AssetUpload> = [];
  readonly maxAssetSize = 10 * 1024 * 1024; //10MB
  loading = false;
  rules = {
    "purchase-value": [
      {
        required: false,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        type: "number",
        message: "El costo debe ser un numero",
      },
    ],
    // purchaseDate: [
    //   {
    //     type: "date",
    //     required: false,
    //     message: "El campo es obligatorio",
    //     trigger: "change",
    //   },
    // ],
    "device-model-id": [
      {
        required: true,
        message: "Por favor seleccionar 1 opcion",
        trigger: "change",
      },
    ],
    "device-location-id": [
      {
        required: true,
        message: "Por favor seleccionar 1 opcion",
        trigger: "change",
      },
    ],
    "device-status-id": [
      {
        required: true,
        message: "Por favor seleccionar 1 opcion",
        trigger: "change",
      },
    ],
    assetsList: [
      {
        required: false,
        validator: this.validatorImage,
        trigger: "change",
      },
    ],
  };

  getModels(idCategory: number) {
    if (typeof this.device["device-model-id"] === "number")
      this.device["device-model-id"] = undefined;
    rhovanion.findModels(idCategory).then(res => {
      this.models = res.rows;
      this.statusCategory = true;
    });
  }

  onUpload(file: AssetUpload, fileList: AssetUpload[]) {
    if (this.beforeUpload(file)) {
      this.assetsList.push(file);
    } else {
      this.$message.error("El tamaño del archivo no puede ser mayor de 10MB");
      fileList.pop();
    }
  }

  onRemove(file: AssetUpload) {
    this.assetsList = this.assetsList.filter(({ uid }) => uid !== file.uid);
  }

  beforeUpload(file: AssetUpload) {
    return file.size < this.maxAssetSize;
  }

  buildFormData(files: Array<AssetUpload>, id: number) {
    const formData = new FormData();
    files.map(({ raw }) => {
      formData.append("file", this.renameFile(raw, id));
    });
    return formData;
  }

  renameFile(image: File, idDevice: number): File {
    const newName = `${idDevice}-${image.name}`;
    return new File([image], newName, { type: image.type });
  }

  createDevice() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        rhovanion
          .createDevice(this.device)
          .then(async res => {
            const formData = this.buildFormData(this.assetsList, res[0].id);
            for (const [name, image] of formData.entries()) {
              const formDataImage = new FormData();
              formDataImage.append(name, image);

              await rhovanion.uploadAsset(res[0].id, formDataImage);
            }
            this.$emit("newDevice");
            Notify.successful("Dispositivo creado exitosamente.");
          })
          .catch(error => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.closeDrawer();
          });
      }
    });
  }

  updateDevice() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate(async (valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        if (this.device && this.device.id) {
          const formData = this.buildFormData(this.assetsList, this.device.id);
          for (const [name, image] of formData.entries()) {
            const formDataImage = new FormData();
            formDataImage.append(name, image);

            await rhovanion.uploadAsset(this.device.id, formDataImage);
          }
          rhovanion
            .updateDevice(this.device.id, this.device)
            .then(() => {
              this.$emit("newDevice");
              Notify.successful("Dispositivo actualizado exitosamente.");
            })
            .catch(error => {
              Notify.gebServerError(error);
            })
            .finally(() => {
              this.closeDrawer();
            });
        }
      }
    });
  }

  closeDrawer() {
    this.loading = false;
    this.endModels();
    this.clearFiles();
    (this.$refs["form"] as HTMLFormElement).clearValidate();
    this.$emit("closeDrawer", false);
  }

  endModels() {
    this.models = [];
    this.categorySelected = null;
    this.statusCategory = false;
  }

  clearFiles() {
    (this.$refs.upload as HTMLFormElement).clearFiles();
    this.assetsList = [];
  }

  async mounted() {
    this.locations = await rhovanion.findDeviceLocation({
      limit: 0,
      offset: 0,
      sort: "+name",
    });
    this.states = await rhovanion.findDeviceStatus();
    this.categories = await rhovanion.findDeviceCategory({
      limit: 0,
      offset: 0,
      sort: "+name",
    });
  }

  beforeUpdate() {
    if (!this.create && this.show && this.models.length === 0) {
      const idCategory = this.categories.rows.find(
        category => category.id === this.device["device-category-id"]
      )?.id as number;
      this.categorySelected = idCategory;
      rhovanion.findModels(idCategory).then(res => {
        this.models = res.rows;
      });
    }
  }

  validatorImage(rule: any, value: any, callback: any) {
    if (this.assetsList.length === 0 && rule.required) {
      callback(new Error("Por favor subir al menos una imagen o documento"));
    } else {
      callback();
    }
  }

  async deviceDataChanged() {
    this.locations = await rhovanion.findDeviceLocation({
      limit: 0,
      offset: 0,
      sort: "+name",
    });
    this.states = await rhovanion.findDeviceStatus();
    this.categories = await rhovanion.findDeviceCategory({
      limit: 0,
      offset: 0,
      sort: "+name",
    });
  }

  @Watch("create")
  onCreateChange() {
    this.rules.assetsList[0].required = this.create;
  }

  @Watch("newDeviceData")
  onNewDeviceDataChange() {
    this.deviceDataChanged();
  }

  get title() {
    return this.create ? "Crear nuevo dispositivo" : "Actualizar dispositivo";
  }

  get showDrawer() {
    return this.show;
  }
}
